<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <div class="webNew-dispflex">
        <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
        <h4 class="hdrtitle backHeading" style="margin-top: 0px">
          Exam Schedule
        </h4>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="row p-2">
              <div class="col-lg-12">
                <div style="box-shadow: none">
                  <div
                    class="widjethdr dispflex"
                    style="border-bottom: 0px solid #efefef"
                  >
                    <div style="width: auto">
                      <!-- <h1>Exam Schedule</h1> -->
                    </div>

                    <div style="width: 14%">
                      <span class="has-float-label" style="margin-top: 4%">
                        <select
                          class="form-control form-input"
                          id="deptlistid"
                          style="text-align: center"
                          v-model="selectedClass"
                          @change="getSection(selectedClass)"
                        >
                          <option :value="null">-- Select --</option>
                          <option
                            v-for="(item, index) in classList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.className }}
                          </option>
                        </select>
                        <label for="clslistid">Select Class </label>
                      </span>
                    </div>
                    <!-- <div style="width: 14%">
                <span class="has-float-label" style="margin-top: 4%">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="selectedSection"
                    @change="getClassTerm"
                  >
                    <option :value="null">-- Select --</option>

                    <option
                      v-for="(item, index) in sectionList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="clslistid">Select Classroom </label>
                </span>
              </div> -->
                    <div style="width: 14%">
                      <span class="has-float-label">
                        <select
                          @change="getExamAcademicInfo(selectTerm)"
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="selectTerm"
                        >
                          <option :value="null">-- Select --</option>
                          <option
                            v-for="(item, index) in termList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <label for="clslistid">Select Term </label>
                      </span>
                    </div>

                    <div class="mt-1" style="margin-right: 29px">
                      <button
                        class="btn gry_bg tooltipt mpopup twoicn"
                        @click="openCreateExamPopUp"
                      >
                        <i class="fas fa-receipt user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Exam</span>
                      </button>
                      <!-- <button
                      type="button"
                      class="backbtn"
                      @click="openCreateExamPopUp"
                    >
                      Create Exam Schedule
                    </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div 
        class="row"
        style="margin-top: 10px"
        v-if="examAcademicList.length > 0"
      >
        <div class="col-lg-12">
          <div class="widjet">
            <div class="admintbl pb-3 mt-1" style="padding: 18px">
              <form
                data-vv-scope="crtAdhocFeeValidate"
                id="crtfeeplans"
                accept-charset="utf-8"
                style="border: 1px solid #efefef"
              >
                <div
                  class="row cust-bg"
                  style="margin-right: 0px; margin-left: 0px"
                >
                  <div class="col-2 cell">Exam Name</div>
                  <!-- <div class="col-1 cell"></div> -->
                  <div class="col-2 cell">Exam Start Date</div>
                  <div class="col-1 cell"></div>
                  <div class="col-2 cell">Exam End Date</div>
                  <div class="col-2 cell" style="padding-left: 16px">
                    Status
                  </div>
                  <div class="col-3 cell">Action</div>
                </div>
                <div v-for="(data, index) in examAcademicList" :key="index">
                  <div
                    class="row cust-lr"
                    style="margin-right: 0px; margin-left: 0px"
                  >
                    <div
                      class="col-2 cell"
                      style="font-weight: bold; padding-left: 2%"
                    >
                      {{ data.name }}
                    </div>
                    <!-- <div class="col-1 cell"></div> -->
                    <div class="col-2 cell" style="padding-left: 20px">
                      {{ data.startDate | dateFormat }}
                    </div>
                    <div class="col-1 cell"></div>
                    <div class="col-2 cell">
                      {{ data.endDate | dateFormat }}
                    </div>
                    <div
                      class="col-2 cell"
                      :style="data.status ==  'Published' ? 'color: #12ed12;' : 'color: orange'"
                    >
                      {{ data.status || 'Saved' }}
                    </div>

                    <div class="col-3 cell">
                      <div class="dispflex" style="justify-content: stretch">
                        <button
                          v-if="data.isView"
                          class="btn round orange tooltipt"
                          style="background: #b3a995"
                          @click.prevent="showViewClose(index)"
                        >
                          <i class="fas fa-angle-up"></i>
                        </button>
                        <button
                          v-if="!data.isView"
                          class="btn round orange tooltipt"
                          style="background: #b3a995"
                          @click.prevent="showView(index)"
                        >
                          <i class="fas fa-angle-down"></i>
                        </button>

                        <button
                          v-if="!data.isView"
                          @click.prevent="openUpdateExamPopUp(data,index)"
                          class="btn round orange tooltipt"
                        >
                          <i class="fas fa-edit"></i
                          ><span class="tooltiptext">Edit</span>
                        </button>
                        <button
                          v-if="!data.isView"
                          class="btn round red tooltipt"
                          @click.prevent="showDeleteModal(data._id)"
                        >
                          <i class="far fa-trash-alt"></i>
                          <span class="tooltiptext">Delete</span>
                        </button>
                        <button
                          v-if="data.isView"
                          style="width: 90px"
                          type="button"
                          class="btn btnsml"
                          id="svbtn"
                          @click.prevent="publishExamScheduledSubjects(data,index)"
                        >
                          Publish
                        </button>
                        <button
                          v-if="data.isView"
                          style="width: 73px"
                          type="button"
                          class="btn btnsml"
                          id="svbtn"
                          @click.prevent="updateExamScheduledSubjects(data)"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="data.isView">
                    <div
                      class="row cust-lr"
                      style="
                        font-weight: bold;
                        padding-left: 1%;
                        background: #e3ecff;
                        margin: 1px;
                      "
                    >
                      <div class="col-2 cell">Subject</div>
                      <div class="col-1 cell">Active</div>
                      <div class="col-2 cell">Exam Date</div>

                      <div class="col-2 cell">Time</div>
                      <div class="col-2 cell">Duration</div>
                      <div class="col-2 cell">Total Marks</div>
                      <div class="col-2 cell"></div>
                    </div>
                    <div
                      class="row cust-lr"
                      style="
                        margin-right: 0px;
                        margin-left: 0px;
                        border-bottom: 1px solid #efefef;
                      "
                      v-for="(ter, issndex) in data.examSubject"
                      :key="issndex"
                    >
                      <div
                        class="col-2 cell"
                        style="
                          padding-left: 2%;
                          border-top: 0x solid #efefef;
                          border-bottom: 0x solid #efefef;
                        "
                      >
                        {{ ter.subjectName }}
                      </div>
                      <div class="col-1 cell">
                        <label class="switch" style="margin-bottom: -15px">
                          <input
                            type="checkbox"
                            id="togBtn"
                            v-model="ter.isExamActive"
                          />
                          <div class="slider"></div>
                        </label>
                        <!-- <label class="custcheckbox">
                            <input type="checkbox" v-model="ter.isExamActive" />
                            <span class="checkmarkchk" style="color:floralwhite;"></span>
                          </label> -->
                      </div>

                      <div class="col-2 cell">
                        <span class="has-float-label">
                          <input
                            v-validate="ter.isExamActive ? 'required' : false"
                            :min="setminmaxDate(data.startDate)"
                            :max="setminmaxDate(data.endDate)"
                            v-model="ter.examDate"
                            type="date"
                            :name="issndex + 'examDate'"
                            class="form-control"
                            id="stdFirstName"
                          />
                        </span>
                        <span
                          class="required"
                          v-if="errors.first(`crtAdhocFeeValidate.${issndex + 'examDate'}`)"
                          style="font-size: small"
                        >
                          Exam Date is required</span
                        >
                      </div>
                      <div class="col-2 cell">
                        <input
                          v-model="ter.time"
                          type="time"
                          v-validate="ter.isExamActive ? 'required' : false"
                          :name="issndex + 'StartTime'"
                          placeholder="Select start time"
                          class="form-control"
                        />
                        <span
                          class="required"
                          v-if="errors.first(`crtAdhocFeeValidate.${issndex + 'StartTime'}`)"
                        >
                          Time is required</span
                        >
                      </div>
                      <div class="col-2 cell">
                        <select
                          v-validate="ter.isExamActive ? 'required' : false"
                          v-model="ter.duration"
                          :name="issndex + 'examperiod'"
                          class="form-control"
                          style="border: 1px solid #d7e1e0"
                          id="deptlistid"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in periodsTime"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                        <span
                          class="required"
                          v-if="errors.first(`crtAdhocFeeValidate.${issndex + 'examperiod'}`)"
                        >
                          Duration is required</span
                        >
                      </div>
                      <div class="col-2 cell">
                        <!-- <span class="has-float-label"> -->
                        <input
                          style="
                            border-radius: 3px;
                            margin-top: 0px;
                            height: 35px;
                            border: 1px solid rgb(215, 225, 224);
                          "
                          v-validate="ter.isExamActive ? 'required|numeric|min:0' : false"
                          v-model="ter.totalMarks"
                          type="number"
                          :min="0"
                          placeholder="Enter Mark"
                          id="stdFirstName"
                          :name="issndex + 'examMark'"
                        />
                        <span
                          class="required"
                          v-if="errors.first(`crtAdhocFeeValidate.${issndex + 'examMark'}`)"
                          style="font-size: small"
                        >
                          Total Marks is required
                        </span>
                        <!-- </span> -->
                      </div>
                      <div class="col-2 cell"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="widjet mt-3" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
            <span style="margin-left: 32%;" >No Exam is scheduled for the selected Class and Term</span>
          </div>
        </div>

      <b-modal
        id="addExamPopUp"
        style="width: 100%"
        no-close-on-backdrop
        no-close-on-esc
      >
        <button type="button" class="close" @click.prevent="closeExamPopUp">
          ×
        </button>
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Exam Schedule</h2>
          <br />
          <div>
            <form id="stuprofile" accept-charset="utf-8">
              <div class="form-row">
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                      v-model="createExam.name"
                      type="text"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Exam Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                      v-model="createExam.startDate"
                      type="date"
                      :min="minAcademicDate"
                      :max="maxAcademicDate"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Start Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                      :disabled="!createExam.startDate"
                      v-model="createExam.endDate"
                      :min="createExam.startDate"
                      :max="maxAcademicDate"
                      type="date"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >End Date<span class="required">*</span></label
                    >
                  </span>
                </div>
                <!-- <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="examAcademicDetails.totalMarks"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Total Marks<span class="required">*</span></label
                  >
                </span>
              </div> -->
              </div>
              <div class="form-row">
                <div class="input-group col-md-6 mt-3">
                  Exam Part of Overall Performance Report ?
                  <span class="required">*</span>

                  <div class="nottble" style="margin-top: 0px">
                    <div class="chkbox" data-title="Select">
                      <label class="custcheckbox">
                        <input type="checkbox" v-model="createExam.isReport" />
                        <span class="checkmarkchk"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="mt-3 pt-3"
                style="border-top: 1px solid #e5e5e5; text-align: center"
              >
                <!-- <div class="dk_icon"></div> -->

                <div class="dk_iconsml">
                  <button
                    :disabled="
                    !createExam.name ||
                    !createExam.startDate ||
                    !createExam.endDate
                  "
                    type="button"
                    class="btn btnsml"
                    @click="saveExamAcademic"
                    id="svbtn"
                  >
                    <span>Create</span>
                  </button>
                  <button
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                    @click="closeExamPopUp"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="updateExamPopUp"
        style="width: 100%"
        no-close-on-backdrop
        no-close-on-esc
      >
        <button
          type="button"
          class="close"
          @click.prevent="closeUpdateExamPopUp"
        >
          ×
        </button>
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Update Exam Schedule</h2>
          <br />
          <div>
            <form id="stuprofile" accept-charset="utf-8">
              <div class="form-row">
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                      v-model="updateExam.name"
                      type="text"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Exam Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                      v-model="updateExam.startDate"
                      type="date"
                      :min="minAcademicDate"
                      :max="maxAcademicDate"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Start Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3">
                  <span class="has-float-label">
                    <input
                      :disabled="!updateExam.startDate"
                      v-model="updateExam.endDate"
                      :min="updateExam.startDate"
                      :max="maxAcademicDate"
                      type="date"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >End Date<span class="required">*</span></label
                    >
                  </span>
                </div>
                <!-- <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="examAcademicDetails.totalMarks"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Total Marks<span class="required">*</span></label
                  >
                </span>
              </div> -->
              </div>
              <div class="form-row">
                <div class="input-group col-md-6 mt-3">
                  Exam Part of Overall Performance Report ?
                  <span class="required">*</span>

                  <div class="nottble" style="margin-top: 0px">
                    <div class="chkbox" data-title="Select">
                      <label class="custcheckbox">
                        <input type="checkbox" v-model="updateExam.isReport" />
                        <span class="checkmarkchk"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
                <!-- <div class="dk_icon"></div> -->

                <div class="text-center dk_iconsml">
                  <button
                    :disabled="
                    !updateExam.name ||
                    !updateExam.startDate ||
                    !updateExam.endDate
                  "
                    type="button"
                    class="btn btnsml"
                    @click="updateExamAcademic"
                    id="svbtn"
                  >
                    <span>Update</span>
                  </button>
                  <button
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                    @click="closeUpdateExamPopUp"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <b-modal id="addAcademicPopUp" style="width: 100%">
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Add Academics</h2>
          <br />
          <div>
            <form id="stuprofile" accept-charset="utf-8">
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <select
                      disabled
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in termList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clsnameid"
                      >Term <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <select
                      class="form-control form-input"
                      id="subjectId"
                      v-model="examAcademicDetails.subject"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in getSubjectAll"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.subjectName }}
                      </option>
                    </select>
                    <label for="clsnameid"
                      >Select Subject <span class="required">*</span></label
                    >
                  </span>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      :min="minAcademicDate"
                      :max="maxAcademicDate"
                      v-model="examAcademicDetails.startDate"
                      type="date"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Start Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      :disabled="!examAcademicDetails.startDate"
                      v-model="examAcademicDetails.endDate"
                      :min="examAcademicDetails.startDate"
                      :max="maxAcademicDate"
                      type="date"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >End Date<span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      v-model="examAcademicDetails.totalMarks"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Total Marks<span class="required">*</span></label
                    >
                  </span>
                </div>
              </div>

              <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
                <!-- <div class="dk_icon"></div> -->

                <div class="text-center dk_iconsml">
                  <button
                    :disabled="
                    !examAcademicDetails.subject ||
                    !examAcademicDetails.startDate ||
                    !examAcademicDetails.endDate ||
                    !examAcademicDetails.totalMarks
                  "
                    type="button"
                    class="btn btnsml"
                    @click="saveExamAcademic"
                    id="svbtn"
                  >
                    <span>Save</span>
                  </button>
                  <button
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                    @click="closeAcademicPopUp"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <b-modal id="addCurricularPopUp" style="width: 100%">
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Add Curricular Activity</h2>
          <br />
          <div>
            <form id="stuprofile" accept-charset="utf-8">
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <select
                      disabled
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in termList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clsnameid"
                      >Term <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <select
                      class="form-control form-input"
                      id="subjectId"
                      v-model="examCurricularDetails.subject"
                    >
                      <option :value="null">--Select--</option>
                      <option
                        v-for="(item, index) in getSubjectCurricularActivities"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.subjectName }}
                      </option>
                    </select>
                    <label for="clsnameid"
                      >Select Subject <span class="required">*</span></label
                    >
                  </span>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      :min="minCurriculumDate"
                      :max="maxCurriculumDate"
                      v-model="examCurricularDetails.startDate"
                      type="date"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Start Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      :disabled="!examCurricularDetails.startDate"
                      :min="examCurricularDetails.startDate"
                      :max="maxCurriculumDate"
                      v-model="examCurricularDetails.endDate"
                      type="date"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >End Date<span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      v-model="examCurricularDetails.curricularActivitiesName"
                      type="text"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Curricular Activity Name<span class="required"
                        >*</span
                      ></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      v-model="examCurricularDetails.totalMarks"
                      class="form-control form-input"
                      id="stdFirstName"
                    />
                    <label for="clsnameid"
                      >Total Marks<span class="required">*</span></label
                    >
                  </span>
                </div>
              </div>

              <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
                <!-- <div class="dk_icon"></div> -->

                <div class="text-center dk_iconsml">
                  <button
                    type="button"
                    :disabled="
                    !examCurricularDetails.subject ||
                    !examCurricularDetails.curricularActivitiesName ||
                    !examCurricularDetails.startDate ||
                    !examCurricularDetails.endDate
                  "
                    class="btn btnsml"
                    @click="saveExamCurriculur"
                    id="svbtn"
                  >
                    <span>Save</span>
                  </button>
                  <button
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                    @click="closeCurricularPopUp"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <b-modal id="examResultPopUp" style="width: 100%">
        <div class="row">
          <div class="col-lg-12">
            <div class="widjet">
              <div class="dispflex"></div>
              <form
                data-vv-scope="crtAdhocFeeValidate"
                id="crtfeeplans"
                accept-charset="utf-8"
              >
                <div class="tbllist">
                  <div class="restable" style="text-align: center">
                    <div class="resrow resheader">
                      <div class="cell col-3">Student Name</div>
                      <div class="cell col-1">Grade</div>
                      <div class="cell col-2">Total Exam Marks</div>

                      <!-- <div class="cell">%</div> -->
                      <div class="cell col-1">Marks</div>
                      <div class="cell col-1">Absent/Present</div>
                      <div class="cell col-4">Comments</div>
                    </div>

                    <div
                      class="resrow"
                      v-for="(data, index) in saveContactResultList"
                      :key="index"
                    >
                      <div class="cell">{{ data.firstName }}</div>
                      <div class="cell">{{ data.grade || "--" }}</div>

                      <div class="cell">{{ data.totalMarks || "--" }}</div>

                      <!-- <div class="cell"> {{ data.percentage ? data.percentage +' '+ '%' : '--' }}</div> -->
                      <!-- <div class="cell"></div> -->
                      <div class="cell">
                        <input
                          type="number"
                          :min="0"
                          :name="index + 'checkmark'"
                          v-validate="{
                          required: false,
                          max_value: termTotalMarks,
                        }"
                          :max="termTotalMarks"
                          :disabled="!data.isAbsent"
                          :style="
                          !data.isAbsent ? 'background-color: #f7f7f7;' : ''
                        "
                          class="form-control form-input text-center"
                          v-model="data.mark"
                        />
                        <span
                          class="required"
                          v-if="
                          errors.first(
                            `crtAdhocFeeValidate.${index + 'checkmark'}`
                          )
                        "
                        >
                          Maximum Marks {{ termTotalMarks }}</span
                        >
                      </div>
                      <div class="cell">
                        <label
                          class="switch"
                          style="margin-left: 14px; margin-bottom: -10px"
                        >
                          <input
                            type="checkbox"
                            id="togBtn"
                            @change="clearMarkValue(data.isAbsent, index)"
                            v-model="data.isAbsent"
                          />
                          <div class="slider"></div>
                        </label>
                      </div>
                      <div class="cell">
                        <input
                          name="comment"
                          class="form-control form-input"
                          id="tocmts"
                          v-model="data.comment"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="widjetfooter text-right dkicon">
                <button
                  type="button"
                  class="btn btnsml"
                  @click="saveExamAcademicResult"
                  id="svbtn"
                >
                  <span>Save</span>
                </button>
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  @click="closeResultPopUp"
                >
                  <span>Back</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="deletePopup">
        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete this record?
          <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              style="width: 12%"
              @click.prevent="confirmDelete"
            >
              <span>Confirm</span>
            </button>
            <button
              @click.prevent="closeDeletePopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";

export default {
  name: "ViTeacherExamSchedule",
  data() {
    return {
      selectedClass: null,
      selectedSection: null,
      classList: [],
      sectionList: [],
      saveContactResultList: [],
      termTotalMarks: null,
      selectPerformanceTerm: null,
      overallPerformanceDetails: {},
      examAcademicDetails: {
        subject: null,
        startDate: null,
        endDate: null,
        totalMarks: null,
        type: "academic",
      },
      examCurricularDetails: {
        subject: null,
        curricularActivitiesName: "",
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "curricularActivitie",
      },
      createExam: {
        name: "",
        startDate: null,
        endDate: null,
        isReport: false
      },
      updateExam: {
        _id: null,
        name: "",
        startDate: null,
        endDate: null,
        isReport: false
      },
      minAcademicDate: null,
      maxAcademicDate: null,
      minCurriculumDate: null,
      maxCurriculumDate: null,
      selectTerm: null,
      subjectList: [],
      termList: [],
      editIndex: null,
      examAcademicList: [],
      examCurricularList: [],
      studentAttendanceList: [],
      examId: null,
      examStartDate: null,
      examSubjectId: null,
      studentAttendanceDetails: {},
      saveStudentAttendanceDetails: {
        date: new Date(),
        isAbsent: false,
        comment: "",
      },
      deleteId: null,
      periodsTime: [
      {
          label: "15 Mins",
          value: 15,
        },
        {
          label: "20 Mins",
          value: 20,
        },
        {
          label: "25 Mins",
          value: 25,
        },
        {
          label: "30 Mins",
          value: 30,
        },
        {
          label: "35 Mins",
          value: 35,
        },
        {
          label: "40 Mins",
          value: 40,
        },
        {
          label: "45 Mins",
          value: 45,
        },
        {
          label: "50 Mins",
          value: 50,
        },
        {
          label: "55 Mins",
          value: 55,
        },
        {
          label: "1 Hours",
          value: 60
        }
      ],
    };
  },
  created() {
    this.getSubjectList();
    this.getClassWithSection();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },

    getSubjectAll() {
      if (this.subjectList.length > 0) {
        return this.subjectList.filter(
          (x) => x.isCurricularActivities == false
        );
      } else {
        return 0;
      }
    },
    getSubjectCurricularActivities() {
      if (this.subjectList.length > 0) {
        return this.subjectList.filter((x) => x.isCurricularActivities == true);
      } else {
        return [];
      }
    },
  },

  methods: {
    previousRoute() {
      let data = localStorage.getItem("previousTab");

      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", this.$route.path);
      this.$router.push({ path: data });
    },
    setminmaxDate(date){
      let termStartDate = new Date(date)
     // termStartDate = new Date(termStartDate.setDate(termStartDate.getDate() + 1));

     let startMonth = String(termStartDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
     let startdate = String(termStartDate.getDate()).padStart(2, "0");

     return termStartDate.getFullYear() + "-" + startMonth + "-" + startdate
    },
    showView(index) {
      this.examAcademicList.forEach((x, i) => {
        if (i == index) {
          x.isView = true;
        } else {
          x.isView = false;
        }
        // newList.push(x)
      });
    },
    showViewClose(index) {
      this.examAcademicList.forEach((x, i) => {
        if (i == index) {
          x.isView = false;
        }

        // newList.push(x)
      });
    },

    async getClassWithSection() {
      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        const response = await ViService.viXGet(
          "/teacher/getAssignedTeacherwithClassSectionList?viewType=list",
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          this.$store.dispatch("setClassAndSectionList", resultData.data);
          this.classList = resultData.data;
          // this.$store.dispatch("setUserTeacherList", resultData.teacherList);
          //this.classList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }
      } else {
        let getClassCounts = this.$store.getters.getClassAndSectionList;

        if (getClassCounts.length == 0) {
          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
            const response = await ViService.viXGet(
              `/academic/class_with_sections`,
              userData.token
            );

            if (response.isSuccess) {
              const resultData = secureUI.secureGet(response.data);
              this.$store.dispatch("setClassAndSectionList", resultData.data);
              this.classList = resultData.data;
            } else {
              this.$toasted.error(response.message);
            }
          }
        } else {
          this.classList = this.$store.getters.getClassAndSectionList;
        }
      }

      if (this.classList.length > 0) {
        this.selectedClass = this.classList[0]._id;
        await this.getSection(this.selectedClass);
      }
    },
    getSection(classId) {
      this.selectedSection = null;
      // this.assignedSubjects = []
      let sections = this.classList.find((x) => x._id == classId).sections;

      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        this.sectionList = sections.filter(
          (x) => x.assignedTeachers.length > 0
        );

        //   if(this.sectionList.length > 0){
        //     for(let x of this.sectionList){
        //       for(let y of x.assignedTeachers){
        //         this.assignedSubjects.push(y.subjectId)
        //       }
        //     }
        //  }

        //  assignedSubjects
      } else {
        this.sectionList = sections;
      }

      if (this.sectionList.length > 0) {
        this.selectedSection = this.sectionList[0]._id;
        this.getClassTerm();
      }
    },

    async getClassTerm() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/class/getAllTerm?classId=${this.selectedClass}`,
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);

          this.termList = data || [];

          if (this.termList.length > 0) {
            if (this.termList.length == 1) {
              this.selectTerm = this.termList[0]._id;
              this.getExamAcademicInfo(this.termList[0]._id, null);

              this.minAcademicDate = this.setminmaxDate(this.termList[0].startDate),
              this.maxAcademicDate = this.setminmaxDate(this.termList[0].endDate)
              // var startDate = new Date(this.termList[0].startDate);
              // var endDate = new Date(this.termList[0].endDate);

              // this.minDate = this.dateFormat(startDate)

              // this.maxDate =  this.dateFormat(endDate)
            } else {
              let currentDate = new Date();

              let checkData = this.termList.filter(
                (x) =>
                  currentDate >= new Date(x.startDate) &&
                  currentDate <= new Date(x.endDate)
              );
              if (checkData.length > 0) {
                this.selectTerm = checkData[0]._id;
                this.getExamAcademicInfo(checkData[0]._id, null);
                this.minAcademicDate = this.setminmaxDate(checkData[0].startDate),
                this.maxAcademicDate = this.setminmaxDate(checkData[0].endDate)
                // var startDate = new Date(checkData[0].startDate);
                // var endDate = new Date(checkData[0].endDate);

                // this.minDate = this.dateFormat(startDate)

                // this.maxDate =  this.dateFormat(endDate)
              } else {
                this.selectTerm = this.termList[0]._id;
                this.getExamAcademicInfo(this.termList[0]._id, null);
                this.minAcademicDate = this.setminmaxDate(this.termList[0].startDate),
                this.maxAcademicDate = this.setminmaxDate(this.termList[0].endDate)
                // var startDate = new Date(this.termList[0].startDate);
                // var endDate = new Date(this.termList[0].endDate);

                // this.minDate = this.dateFormat(startDate)

                // this.maxDate =  this.dateFormat(endDate)
              }
            }
          }
          //            this.getExamAcademicInfo(this.termList[0]._id);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    getSubjectName(id) {
      if (id) {
        if (this.subjectList.length > 0) {
          let details = this.subjectList.filter((x) => x._id == id);
          if (details.length > 0) {
            return details[0].subjectName;
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },
    async getSubjectList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/class/getSubjectList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    clearMarkValue(data, index) {

      if (!data) {
        this.saveContactResultList[index].mark = null;
      }
      //saveContactResultList.isAbsent
    },
    async updateExamScheduledSubjects(data){
      let userData = secureUI.sessionGet("user");

    if (!userData) {
      this.$toasted.error("Please login and do the action");
      this.$router.push("/login");
    } else {
      let isFormValid = false;

await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
  isFormValid = result;
});

if(isFormValid){
      let allowSave = true;

      if (allowSave) {
        let obj = {
          updateList: data
        }
      // this.createExam["class"] = this.selectedClass;
        //  this.examAcademicDetails["classroom"] = this.selectedSection;
      // this.createExam["term"] = this.selectTerm;

        const response = await ViService.viXPost(
          "/academic/updateExamSubjects",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);

        } else {
          this.$toasted.error(response.message);
        }
      } else {
        this.$toasted.error("Subject Already Created");
      }
      }
    }
    },
    async publishExamScheduledSubjects(data,index){
      let userData = secureUI.sessionGet("user");

    if (!userData) {
      this.$toasted.error("Please login and do the action");
      this.$router.push("/login");
    } else {

      let isFormValid = false;

      await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){

      let allowSave = true;

      if (allowSave) {
        let obj = {
          updateList: data
        }
      // this.createExam["class"] = this.selectedClass;
        //  this.examAcademicDetails["classroom"] = this.selectedSection;
      // this.createExam["term"] = this.selectTerm;

        const response = await ViService.viXPost(
          "/academic/publishExamSubjects",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.examAcademicList[index].status = 'Published'
          this.examAcademicList[index].isView = false

        // this.closeExamPopUp();

        // this.getExamAcademicInfo(this.selectTerm);
        } else {
          this.$toasted.error(response.message);
        }
      } else {
        this.$toasted.error("Subject Already Created");
      }

      }else{
        this.examAcademicList[index].isView = true
       }
      }
    },

    async saveExamAcademic() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let allowSave = true;
        // if (this.examAcademicList.length > 0) {
        //   let details = this.examAcademicList.find((x) => x.subject == this.examAcademicDetails.subject);
        //   if (details) {
        //     allowSave = false;
        //   } else {
        //     allowSave = true;
        //   }
        // } else {
        //   allowSave = true;
        // }
        if (allowSave) {
          this.createExam["class"] = this.selectedClass;
          //  this.examAcademicDetails["classroom"] = this.selectedSection;
          this.createExam["term"] = this.selectTerm;

          const response = await ViService.viXPost(
            "/academic/examAcademic/save",
            this.createExam,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeExamPopUp();
            this.getExamAcademicInfo(this.selectTerm);
          } else {
            this.$toasted.error(response.message);
          }
        } else {
          this.$toasted.error("Subject Already Created");
        }
      }
    },
    async updateExamAcademic() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let allowSave = true;
        // if (this.examAcademicList.length > 0) {
        //   let details = this.examAcademicList.find((x) => x.subject == this.examAcademicDetails.subject);
        //   if (details) {
        //     allowSave = false;
        //   } else {
        //     allowSave = true;
        //   }
        // } else {
        //   allowSave = true;
        // }
        if (allowSave) {
         // this.createExam["class"] = this.selectedClass;
          //  this.examAcademicDetails["classroom"] = this.selectedSection;
          //this.createExam["term"] = this.selectTerm;

          const response = await ViService.viXPost(
            "/academic/examAcademic/updateExam",
            this.updateExam,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeUpdateExamPopUp();
           await this.getExamAcademicInfo(this.selectTerm);
           this.examAcademicList[this.editIndex].isView = true


          } else {
            this.$toasted.error(response.message);
          }
        } else {
          this.$toasted.error("Subject Already Created");
        }
      }
    },

    // saveExamAcademic
    async saveExamCurriculur() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.examCurricularDetails["class"] = this.selectedClass;
        this.examCurricularDetails["classroom"] = this.selectedSection;
        this.examCurricularDetails["term"] = this.selectTerm;

        const response = await ViService.viXPost(
          "/academic/examAcademic/save",
          this.examCurricularDetails,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.closeCurricularPopUp();
          this.getExamAcademicInfo(this.selectTerm);
        } else {
          this.$toasted.error(response.message);
        }
      }
    },

    async saveExamAcademicResult() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator
        .validateAll("crtAdhocFeeValidate")
        .then((result) => {
          isFormValid = result;
        });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          let obj = {
            updateData: this.saveContactResultList,
            class: this.selectedClass,
            classroom: this.selectedSection,
            term: this.selectTerm,
            exam: this.examId,
            totalMarks: this.termTotalMarks,
            examStartDate: this.examStartDate,
            subject: this.examSubjectId,
          };

          const response = await ViService.viXPost(
            "/academic/examAcademic/result/save",
            obj,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeResultPopUp();
            this.getStudentAttendanceInfo();
          } else {
            this.$toasted.error(response.message);
          }
        }
      }
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/academic/deleteExam/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {


          this.$toasted.success(response.message);
              this.getExamAcademicInfo(this.selectTerm);

         // this.isEdit = false;
          this.closeDeletePopUp();
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
   //   this.viLoader = false;
    },
    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deletePopup");
    },
    closeDeletePopUp(){
      this.$bvModal.hide("deletePopup");
      this.deleteId = null;
    },

    async getExamAcademicInfo(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (id) {
          let requestBody = {
            class: this.selectedClass,
            term: id,
          };

          const response = await ViService.viXPost(
            "/academic/getExamAcademicInfo",
            requestBody,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);

            this.examAcademicList = resultData;

            if(this.examAcademicList.length > 0){
              for(let x of this.examAcademicList){
                if(x.examSubject.length > 0){
                  for(let y of x.examSubject){
                    y.examDate = y.examDate ? y.examDate.substring(0, 10) : null
                  }

                }

              }

            }

          } else {
            this.$toasted.error(response.message);
          }
        } else {
          (this.examAcademicList = []), (this.examCurricularList = []);
        }
      }
    },

    openAcademicPopUp() {
      this.$bvModal.show("addAcademicPopUp");
    },
    closeAcademicPopUp() {
      this.$bvModal.hide("addAcademicPopUp");
      this.examAcademicDetails = {
        subject: null,
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "academic",
      };
    },
    openCurricularPopUp() {
      this.$bvModal.show("addCurricularPopUp");
    },
    closeCurricularPopUp() {
      this.$bvModal.hide("addCurricularPopUp");
      this.examCurricularDetails = {
        subject: null,
        curricularActivitiesName: "",
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "curricularActivitie",
      };
    },
    openCreateExamPopUp() {
      this.$bvModal.show("addExamPopUp");
    },
    closeExamPopUp() {
      this.$bvModal.hide("addExamPopUp");
      this.createExam = {
        name: "",
        startDate: null,
        endDate: null,
      };
    },
    openUpdateExamPopUp(data,index) {
     // this.updateExam. = {
        this.updateExam._id = data._id,
        this.updateExam.name = data.name,
        this.updateExam.startDate = data.startDate.substring(0, 10),
        this.updateExam.endDate = data.endDate.substring(0, 10),
        this.updateExam.isReport = data.isReport || false
        this.editIndex = index
      //};
      this.$bvModal.show("updateExamPopUp");
    },
    closeUpdateExamPopUp() {
      this.$bvModal.hide("updateExamPopUp");
      this.updateExam = {
        _id: null,
        name: "",
        startDate: null,
        endDate: null,
        isReport: false
      };
      //this.editIndex = null
    },
    openAttendancePopUp(data) {
      let currentDate = new Date();

      let startmonth = currentDate.getMonth() + 1;
      let startdate = currentDate.getDate();

      if (startdate < 10) {
        startdate = "0" + startdate;
      }
      if (startmonth < 10) {
        startmonth = "0" + startmonth;
      }

      this.saveStudentAttendanceDetails.date =
        currentDate.getFullYear() + "-" + startmonth + "-" + startdate;

      this.studentAttendanceDetails = data;
      this.$bvModal.show("addAttendancePopUp");
    },
    closeAttendancePopUp() {
      this.$bvModal.hide("addAttendancePopUp");
    },
    async getExamAcademicStudentInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          term: this.selectTerm,
          class: this.selectedClass,
          classroom: this.selectedSection,
          // class: this.selectedClass,
          // classroom: this.selectedSection,
          exam: this.examId,
        };

        const response = await ViService.viXPost(
          "/academic/getExamAcademicStudentInfo",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          if (resultData.examResultData.length > 0) {
            for (let x of resultData.examResultData) {
              this.saveContactResultList.push({
                mark: x.mark,
                grade: x.grade,
                percentage: x.percentage || "",
                totalMarks: x.totalMarks || "",
                student: x.contact._id,
                firstName: x.contact.firstName,
                lastName: x.contact.lastName,
                isAbsent: x.isAbsent,
                comment: x.comment,
              });
            }
          } else {
            for (let x of resultData.contactList) {
              this.saveContactResultList.push({
                grade: "",
                mark: 0,
                student: x._id,
                firstName: x.firstName,
                lastName: x.lastName,
                isAbsent: true,
                comment: "",
              });
            }
          }
        } else {
          this.$toasted.error(response.message);
        }
      }
    },

    openResultPopUp(id, subId, date, mark) {
      this.termTotalMarks = null;
      this.termTotalMarks = mark;
      this.examId = id;
      (this.examSubjectId = subId),
        (this.examStartDate = date),
        this.$bvModal.show("examResultPopUp");
      this.getExamAcademicStudentInfo();
    },
    closeResultPopUp() {
      this.termTotalMarks = null;
      this.examId = null;
      (this.examStartDate = null), (this.examSubjectId = null);
      this.$bvModal.hide("examResultPopUp");
      this.saveContactResultList = [];
    },
  },

  components: {
    FullCalendar,
  },
};
</script>

<style></style>
