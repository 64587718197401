<template>
  <div v-bind:style="styles">
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="card">
              <div class="card-body">
                <p>
                  <span class="d-flex align-items-center gap-2 card_left_text"
                    ><img
                      src="../../assets/img/attendence.png"
                      alt="birthday"
                    /><span style="margin-left: 3px"> Attendance</span>
                  </span>

                  <span class="veiw_all">
                    <a style="color: blue" @click.prevent="navToAttendance"
                      >View All</a
                    >
                  </span>
                </p>
                <br />
                <div
                  class="attend_time d-flex flex-wrap justify-content-between"
                >
                  <div class="calender d-flex flex-column">
                    <span class="time mb-2">{{ timestamp }}</span>
                    <span class="date"> {{ fullTimeStamp }}</span>
                    <span class="day"> {{ fullTimeStampDay }}</span>
                  </div>
                  <div class="clock_btn">

                    <button type="button" class="btn btnsml" id="svbtn" style="width: 104%;"

                    :disabled="
                        clockOutBody.status == 'Leave' ||
                        clockOutBody.status == 'Pending Approval' ||
                        (clockedInTime && clockedOutTime)
                      "
                      v-if="!isClockedIn"
                      @click.prevent="clockIn"
                    >
                    <span>Clock In</span> <i v-if="showloader" class='fa fa-spinner fa-spin'></i>
                  </button>

                    <!-- <button
                      class="btn"
                      style="background-color: var(--button-bg)"
                      :disabled="
                        clockOutBody.status == 'Leave' ||
                        clockOutBody.status == 'Pending Approval' ||
                        (clockedInTime && clockedOutTime)
                      "
                      v-if="!isClockedIn"
                      @click.prevent="clockIn"
                    >
                      <span>Clock In</span>
                      <i v-if="showloader" class="fa fa-spinner fa-spin"></i>
                    </button> -->

                    <button type="button" class="btn btnsml" id="svbtn" style="width: 104%;"
                    v-if="isClockedIn" 
                    @click.prevent="clockOut">
                    <span>Clock Out</span> <i v-if="showloader" class='fa fa-spinner fa-spin'></i>
                  </button>

                    <!-- <button
                      class="btn"
                      style="background-color: var(--button-bg)"
                      v-if="isClockedIn"
                      @click.prevent="clockOut"
                    >
                      <span>Clock Out</span>
                      <i v-if="showloader" class="fa fa-spinner fa-spin"></i>
                    </button> -->
                  </div>
                  <div class="cin_cout">
                    <div class="card inner_card" style="border-radius: 9px;">
                      <!-- <div class="icon">
                        <img
                          src="../../assets/img/Group.svg"
                          class="img-fluid svg_img"
                        />
                      </div> -->
                      <span class="chec_kin"><a href="javascript:void(0);">Clock In</a></span>
                      <span class="day">{{
                        clockedInTime != null ? clockedInTime : "-- : --"
                      }}</span>
                    </div>
                    <div class="card inner_card" style="border-radius: 9px;">
                      <!-- <div class="icon">
                        <img
                          src="../../assets/img/Group 7.png"
                          class="img-fluid"
                        />
                      </div> -->
                      <span class="chec_kin"><a href="javascript:void(0);">Clock Out</a></span>
                      <span class="day">{{
                        clockedOutTime != null ? clockedOutTime : "-- : --"
                      }}</span>
                    </div>
                  </div>
                  <span style="position: absolute;
    top: 142px;
    right: 168px;">Status: {{ clockOutBody.status }}</span>
                </div>
              </div>
            </div>
            <!--------------------------LEAVE CARD------------------------------------------->

            <!---------------------holiday card------------------------->
            <div class="card mt-3">
              <div class="card-body">
                <p>
                  <span class="d-flex align-items-center gap-2 card_left_text"
                    ><img
                      src="../../assets/img/birthday.png"
                      alt="birthday"
                    /><span style="margin-left: 3px"> Birthdays</span></span
                  >

                  <span class="veiw_all">
                    <a
                      style="color: blue; text-align: end"
                      @click.prevent="openBirthdaypopup"
                      >View All</a
                    >
                  </span>
                </p>
                <div class="t_body">
                  <table class="table birthday_table text-center">
                    <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Date of Birth</th>
                        <th>Class</th>
                        <th>Division</th>
                      </tr>
                    </thead>

                    <tbody>
                      <!-- Add your table rows here -->
                      <tr
                        v-for="(student, index) in birthdayStudentList"
                        :key="index"
                      >
                        <td>
                          {{ student.firstName + " " + student.lastName }}
                        </td>
                        <td>{{ student.dob | date }}</td>
                        <td>
                          {{ student.class ? student.class.className : "" }}
                        </td>
                        <td>
                          {{ student.section ? student.section.name : "" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
             
            </div>
          </div>

          <!---------------------------------Birthday Card------------------------->
          <div class="col-lg-4 col-md-5 col-sm-12">
            <div class="card" style="margin-right: 11px;" >
              <div class="card-body">
                <p class="d-flex justify-content-between">
                  <span class="d-flex align-items-center gap-2 card_left_text"
                    ><img
                      src="../../assets/img/Group 14.svg"
                      alt="birthday"
                    /><span style="margin-left: 3px"> Leaves</span></span
                  >
                  <span>
                    <!-- button class="btn">Apply Leaves</button -->
                    <button type="button" class="btn btnsml" id="svbtn" style="width: 104%;"
                    @click.prevent="openLeavePopUp">
                    <span>Apply Leave</span>
                  </button>

                 
                  </span>

                  <span class="veiw_all">
                    <a style="color: blue" @click.prevent="navToLeave"
                      >View All</a
                    >
                  </span>
                </p>
              </div>
            </div>
            <div class="card mt-3" style="margin-right: 11px;">
              <div class="card-body">
                <p>
                  <span class="d-flex align-items-center gap-2 card_left_text"
                    ><img
                      src="../../assets/img/holidays.svg"
                      alt="birthday"
                    /><span style="margin-left: 3px"> Holidays</span></span
                  >

                  <span class="veiw_all">
                    <a
                      style="color: blue; text-align: end"
                      @click.prevent="openHolidaypopup"
                      >View All</a
                    >
                  </span>
                </p>
                <div class="t_body">
                  <table class="table birthday_table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Name of holiday</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(student, index) in holidayList" :key="index">
                        <td>{{ student.date | dateFormat }}</td>
                        <td>{{ student.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- STUDENT MESSAGES -->
          </div>
          <!------------------------------------------------staff side bar------------------>
          <div class="col-lg-3 col-md-2 col-sm-6 p-0">
            <div class="card">
              <div class="card-body staff_side_bar" style="border-radius: 30px;">
                <p
                  style="text-align: center"
                  img
                  src="../../assets/img/message.png"
                  alt=""
                >
                  <span
                    class="message"
                    style="
                      padding: 0px;
                      margin: 0px;
                      color: black;
                      font-weight: 700;
                    "
                    >Message</span
                  >
                </p>
                <div class="tabs" style="height: 2%; background: none">
                  <input
                    type="radio"
                    name="tabs"
                    id="classestab"
                    checked="checked"
                  />
                  <label
                    for="classestab"
                    style="
                      border: 1px solid rgb(192, 196, 233);
                      border-radius: 15px 15px 0px 0px;
                      margin-left: 67px;
                    "
                    >Staff
                  </label>
                  <div class="tab">
                    <input
                      class="form-control me-1 search_staff"
                      type="search"
                      placeholder="Type Name"
                      v-model="searchWords"
                      aria-label="Search"
                    />

                    <!-- <input class="form-control me-1 search_staff" type="search" placeholder="Type Name" aria-label="Search" v-model="searchWords"> -->
                                       
                                        <div class="staff d-flex" v-for="(data, index) in adminList" :key="index" style="cursor: pointer;">
                                            <div class="staff_pic"  @click="openAdminChat(data)">
                                              <div class="std-name"> {{ data.firstName.charAt(0) }}</div>

                                                <div class="status-circle" v-if="data.message.length > 0">{{ data.message.length }}</div>

                                            </div>
                                            <div class="staff_info ms-2" style="margin-top: 0px;"  @click="openAdminChat(data)">
                                                <span class="staff_name"></span><br>
                                                <span class="staff_desig" style="margin-left: 3px;">{{ data.firstName }}</span>
                                            </div>
                                        </div>
                  </div>
                  <input type="radio" name="tabs" id="ss" />
                  <label
                    for="ss"
                    style="
                      border: 1px solid rgb(192, 196, 233);
                      border-radius: 15px 15px 0px 0px;
                    "
                    >Student
                  </label>
                  <div class="tab">
                    <!-- <div> -->
                    <div class="form-row">
                      <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                        <span class="has-float-label">
                    <select
                      v-validate="'required'"
                      class="form-control form-input"
                      id="deptlistid"
                      name="classvisitor"
                      v-model="messageDetails.class"
                      @change="getSection(messageDetails.class)"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in classList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.className }}
                      </option>
                    </select>
                    <label
                      v-if="errors.first('admissionValidate.classvisitor')"
                      class="vi-error"
                      style="top: -1em"
                      >Class is required</label
                    >
                    <label for="deptid" v-else
                      > Class </label
                    >
                  </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                        <span class="has-float-label">
                    <select
                      v-validate="'required'"
                      @change="getStudentList"
                      class="form-control form-input"
                      id="deptlistid"
                      name="sectionvisitor"
                      v-model="messageDetails.classRoom"
                    >
                      <option :value="null">-- Select --</option>

                      <option
                        v-for="(item, index) in sectionList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label
                      v-if="errors.first('admissionValidate.sectionvisitor')"
                      class="vi-error"
                      style="top: -1em"
                      >Classroom is required</label
                    >
                    <label for="deptid" v-else style="width: 126%;"
                      > Classroom </label
                    >
                  </span>
                      </div>
                      <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3" style="margin-left: 5px;">
                    
                    <input class="form-control me-1 search_staff" v-model="studentsearchWords" type="search" placeholder="Type Name" aria-label="Search">

                 
                </div> -->
                    </div>
                    <div class="form-row">
                      <input style="margin-right: 13px;margin-left: 10px;"
                        class="form-control me-1 search_staff"
                        type="search"
                        placeholder="Type Name"
                        v-model="studentsearchWords"
                        aria-label="Search"
                      />
                    </div>
                    <div
                      class="staff d-flex"
                      v-for="(data, index) in studentList"
                      :key="index"
                    >
                      <div class="staff_pic" @click.prevent="openChat(data)">
                        <div class="std-name">
                          {{ data.firstName.charAt(0) }}
                        </div>

                        <div
                          class="status-circle"
                          style="
                            width: 18px;
                            height: 17px;
                            color: white;
                            font-size: 9px;
                          "
                          v-if="data.message.length > 0"
                        >
                          {{ data.message.length }}
                        </div>
                      </div>
                      <div
                        class="staff_info ms-2"
                        @click.prevent="openChat(data)"
                      >
                        <span class="staff_name"> {{ data.firstName }}</span
                        ><br />
                      </div>
                    </div>


                  </div>
                </div>
                <!-- <p> <img src="../../assets/img/message.png" alt=""><span class="message" style="padding: 0px;margin: 0px;">Admin Message</span></p>
                        
                                        <span >Search Admin</span>
                                        <input class="form-control me-1 search_staff" type="search" placeholder="Type Name" aria-label="Search" v-model="searchWords">
                                        <hr>
                                        <div class="staff d-flex" v-for="(data, index) in adminList" :key="index" style="cursor: pointer;">
                                            <div class="staff_pic"  @click="openAdminChat(data)">
                                              <div class="std-name"> {{ data.firstName.charAt(0) }}</div>

                                                <div class="status-circle" v-if="data.message.length > 0">{{ data.message.length }}</div>

                                            </div>
                                            <div class="staff_info ms-2"  @click="openAdminChat(data)">
                                                <span class="staff_name"></span><br>
                                                <span class="staff_desig" style="margin-left: 3px;">{{ data.firstName }}</span>
                                            </div>
                                        </div>
                                       
                                        <div class="text-center">
                                        </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        id="leaveApplyPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
        <button type="button" class="close" @click.prevent="closeLeavePopUp">
          ×
        </button>
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Apply Leave</h2>
          <br />
          <div>
            <form
              data-vv-scope="admissionValidate"
              id="crtadmission"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="date"
                      :min="minDatesLeaveApply"
                      :max="maxDatesLeaveApply"
                      v-model="leaveDetails.from"
                      onkeydown="return false"
                      @change="checkToDate"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >From Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="date"
                      :min="leaveDetails.from"
                      :max="maxDatesLeaveApply"
                      :disabled="!leaveDetails.from"
                      v-model="leaveDetails.to"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >To Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              </div>
              <div class="form-row">
                <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
                <div class="form-group input-group col-md-9 mt-3 pr-md-3">
                  <span style="margin-top: 6px; color: #2954a2">Reason : </span>
                  <div style="margin-top: 8px; width: 90%">
                    <label
                      class="custradio"
                      style="
                        margin-left: 6px;
                        margin-bottom: 0px;
                        border-radius: 21px;
                        width: 144px;
                      "
                      >Planned Leave
                      <input
                        type="radio"
                        v-model="leaveDetails.reason"
                        value="Planned Leave"
                        name="schedule"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label
                      class="custradio"
                      style="
                        margin-bottom: 0px;
                        border-radius: 21px;
                        width: 110px;
                      "
                      >Sick Leave
                      <input
                        type="radio"
                        v-model="leaveDetails.reason"
                        value="Sick Leave"
                        name="schedule"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <!-- <label
                      class="custradio"
                      style="
                        margin-bottom: 0px;
                        border-radius: 21px;
                        width: 50px;
                      "
                      >Fever
                      <input
                        type="radio"
                        v-model="leaveDetails.reason"
                        value="Fever"
                        name="schedule"
                      />
                      <span class="checkmark"></span>
                    </label> -->
                    <label
                      class="custradio"
                      style="
                        margin-bottom: 0px;
                        border-radius: 21px;
                        width: 0px;
                      "
                      >Others
                      <input
                        type="radio"
                        v-model="leaveDetails.reason"
                        value="Others"
                        name="schedule"
                      />
                      <span class="checkmark"></span>
                    </label>

                    <!-- <input type="radio" id="Planned Leave" name="options" v-model="leaveDetails.reason"
                      value="Planned Leave">
                    <label class="custLabel" for="Planned Leave">Planned Leave</label>

                    <input type="radio" id="Sick Leave" name="options" v-model="leaveDetails.reason" value="Sick Leave">
                    <label class="custLabel" for="Sick Leave">Sick Leave</label>

                    <input type="radio" id="Fever" name="options" v-model="leaveDetails.reason" value="Fever">
                    <label class="custLabel" for="Fever">Fever</label>
                    <input type="radio" id="Others" name="options" v-model="leaveDetails.reason" value="Others">
                    <label class="custLabel" for="Others">Others</label> -->
                  </div>
                </div>
              </div>
              <div class="form-row" v-if="leaveDetails.from && leaveDetails.to">
                <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span>No Of Days : </span>
                  <span style="margin-left: 7px">{{ noOfDaysLeave }} </span>
                  <!-- <div>
                ssss
                </div> -->
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              </div>
              <div class="form-row">
                <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <textarea
                      class="form-control web-form-input"
                      style="
                        min-height: 135px;
                        resize: none;
                        border: 1px solid #ccc;
                      "
                      id="message"
                      v-model="leaveDetails.notes"
                    ></textarea>
                    <!-- <input
                      type="date"
                      v-model="leaveDetails.notes"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    /> -->

                    <label for="deptid" style="margin-top: -6px"
                      >Comments
                    </label>
                  </span>
                  <!-- </div> -->
                </div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div>
              </div>

              <div class="widjetfooter">
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button
                    type="button"
                    :disabled="
                      !leaveDetails.from ||
                      !leaveDetails.to ||
                      noOfDaysLeave == 0
                    "
                    class="btn btnsml"
                    id="svbtn"
                    @click="updateLeaveApply"
                  >
                    <span>Apply</span>
                  </button>
                  <button
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                    @click="closeLeavePopUp"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="birthDayPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
        <button type="button" class="close" @click.prevent="closeBirthDayPopUp">
          ×
        </button>
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Birthdays</h2>
          <br />
          <div>
            <form
              data-vv-scope="admissionValidate"
              id="crtadmission"
              accept-charset="utf-8"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="widjet">
                    <div class="widjetcontent">
                      <div>
                        <div class="restable">
                          <div class="resrow resheader">
                            <div class="cell">Student Name</div>
                            <div class="cell">Date Of Birth</div>

                            <div class="cell">Class</div>
                            <div class="cell">Classroom</div>
                          </div>
                          <div
                            class="resrow"
                            v-for="(student, index) in birthdayStudentAllList"
                            :key="index"
                          >
                            <div class="cell" data-title="Student Name">
                              {{ student.firstName + " " + student.lastName }}
                            </div>
                            <div class="cell" data-title="Student Name">
                              {{ student.dob | date }}
                            </div>

                            <div class="cell" data-title="Student Name">
                              {{ student.class ? student.class.className : "" }}
                            </div>
                            <div class="cell" data-title="Student Name">
                              {{ student.section ? student.section.name : "" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="widjetfooter" style="margin-top: 12px">
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                    @click="closeBirthDayPopUp"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <b-modal id="holidayPopUp" class="modal">
        <button type="button" class="close"  @click.prevent="closeHolidayPopUp">×</button>

        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Holiday</h2>
          <br />
          <div>
            <form
              data-vv-scope="admissionValidate"
              id="crtadmission"
              accept-charset="utf-8"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="widjet">
                    <div class="widjetcontent">
                      <div>
                        <div class="restable">
                          <div class="resrow resheader">
                            <div class="cell">Date</div>
                            <div class="cell">Name</div>
                          </div>
                          <div
                            class="resrow"
                            v-for="(student, index) in allHolidayList"
                            :key="index"
                          >
                            <div class="cell" data-title="Student Name">
                              {{ student.date | dateFormat }}
                            </div>
                            <div class="cell" data-title="Student Name">
                              {{ student.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="widjetfooter">
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                    @click="closeHolidayPopUp"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="messageStudentPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
        <button type="button" class="close" @click.prevent="closeStudentChat">
          ×
        </button>
        <div class="contact-form">
          <!-- <span class="close" @click.prevent="closeStudentChat">&times;</span> -->
          <h2>
            {{ studentDetails.firstName + " " + studentDetails.lastName }}
          </h2>
          <br />

          <div class="chat-container" id="chat-container">
            <div
              v-for="(group, day) in Object.keys(groupedStudentMessages)"
              :key="day"
            >
             
                <div style="text-align: center">
                  <span v-if="groupedStudentMessages[group].length > 1"
                    style="
                      padding: 3px;
                      border-radius: 10px;
                      background-color: rgb(240 238 247);
                    "
                    >{{ formatDayHeader(group) }}</span
                  >
                </div>
                <div
                  v-for="message in groupedStudentMessages[group]"
                  :key="message._id"
                >
                  <div
                    class="message"
                    style="margin: 0px"
                    v-if="message.fromUserType == 'Teacher'"
                  >
                    <div class="message sender-message" style="margin: 0px;width: 300px;">
                      {{ message.message }}
                      <div class="timestamp">
                        {{ formatTime12Hour(message.date) }}
                      </div>
                    </div>
                  </div>
                  <div class="message receiver-message" v-else style="width: 300px;">
                    <div>{{ message.message }}</div>
                    <div class="timestamp">
                      {{ formatTime12Hour(message.date) }}
                    </div>
                  </div>
                </div>
             
            </div>
          </div>
          <div class="row">
            <div class="col-lg-9">
              <textarea
                style="width: 103%"
                v-model="studentMessage"
                @keyup.enter="handleKeyPress"
                class="message-input"
                placeholder="Type your message..."
              ></textarea>
            </div>
            <div class="col-lg-2">
              <button
                style="margin-top: 12px; padding: 19px"
                class="send-button"
                @click.prevent="sendToStudent"
              >
                Send
              </button>
            </div>
            <div class="col-lg-1" style="text-align: center">
              <li class="addropdown">
                <span
                  class="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-hover="dropdown"
                  data-close-others="true"
                >
                  <button
                    style="
                      margin-top: 12px;
                      padding: 19px;
                      margin-left: -33px;
                      background-color: gray;
                    "
                    class="send-button"
                  >
                    ...
                  </button>
                </span>
                <div class="addropdown-content wow bounceIn">
                  <div class="arrow-up"></div>
                  <div class="addropmenu" style="background-color: #dbefe3">
                    <span class="usernamemob">.sss..</span>
                    <label class="web-custradio" style="margin-right: 10px"
                      >Press enter to send message
                      <input
                        type="radio"
                        name="regRadio"
                        @change="showOptions"
                        v-model="isEnterBtnEnable"
                        value="enter"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                    <label class="web-custradio" style="margin-right: 10px"
                      >Click send to send message
                      <input
                        type="radio"
                        name="regRadio"
                        @change="showOptions"
                        v-model="isEnterBtnEnable"
                        value="send"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                  </div>
                </div>
              </li>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="messageAdminPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
        <button type="button" class="close" @click.prevent="closeAdminChat">
          ×
        </button>
        <div class="contact-form">
          <!-- <span class="close" @click.prevent="closeAdminChat">&times;</span> -->
          <h2>{{ adminDetails.firstName + " " + adminDetails.lastName }}</h2>
          <br />

          <div class="chat-container" id="chat-container">
            <div
              v-for="(group, day) in Object.keys(groupedAdminMessages)"
              :key="day"
            >
                <div style="text-align: center">
                  <span v-if="groupedAdminMessages[group].length > 0"
                    style="
                       padding: 3px;
                      border-radius: 10px;
                      background-color: rgb(240 238 247);
                    "
                  >
                    {{ formatDayHeader(group) }}</span
                  >
                </div>
                <div
                  v-for="message in groupedAdminMessages[group]"
                  :key="message._id"
                >
                  <div
                    class="message"
                    style="margin: 0px"
                    v-if="message.fromUserType == 'Teacher'"
                  >
                    <div class="message sender-message" style="margin: 0px;width: 300px;">
                      {{ message.message }}
                      <div class="timestamp">
                        {{ formatTime12Hour(message.date) }}
                      </div>
                    </div>
                  </div>
                  <div class="message receiver-message" v-else style="width: 300px;">
                    <div>{{ message.message }}</div>
                    <div class="timestamp">
                      {{ formatTime12Hour(message.date) }}
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- <div> -->
          <div class="row">
            <div class="col-lg-9">
              <textarea
                style="width: 103%"
                v-model="adminMessage"
                @keyup.enter="handleKeyPressAdmin"
                class="message-input"
                placeholder="Type your message..."
              ></textarea>
            </div>
            <div class="col-lg-2" >
              <button
                style="margin-top: 12px; padding: 19px"
                class="send-button"
                @click.prevent="sendToAdmin"
              >
                Send
              </button>
            </div>
            <div class="col-lg-1" style="text-align: center">
              <li class="addropdown">
                <span
                  class="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-hover="dropdown"
                  data-close-others="true"
                >
                  <button
                    style="
                      margin-top: 12px;
                      padding: 19px;
                      margin-left: -33px;
                      background-color: gray;
                    "
                    class="send-button"
                  >
                    ...
                  </button>
                </span>
                <div class="addropdown-content wow bounceIn">
                  <div class="arrow-up"></div>
                  <div class="addropmenu" style="background-color: #dbefe3">
                    <span class="usernamemob">.sss..</span>
                    <label class="web-custradio" style="margin-right: 10px"
                      >Press enter to send message
                      <input
                        type="radio"
                        name="regRadio"
                        @change="showOptions"
                        v-model="isEnterBtnEnable"
                        value="enter"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                    <label class="web-custradio" style="margin-right: 10px"
                      >Click send to send message
                      <input
                        type="radio"
                        name="regRadio"
                        @change="showOptions"
                        v-model="isEnterBtnEnable"
                        value="send"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                  </div>
                </div>
              </li>
            </div>
          </div>

          <!-- <textarea
              style="width: 86%"
              v-model="adminMessage"
              class="message-input"
              placeholder="Type your message..."
            ></textarea
            ><button class="send-button" @click.prevent="sendToAdmin">
              Send
            </button> -->
          <!-- </div> -->
          <!-- </div>
            </div>
          </div> -->
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import { socket } from "../../configuration/socketio";

import "vue-awesome/icons/user";

export default {
  name: "TeacherHome",
  data() {
    return {
      timestamp: "",
      fullTimeStamp: "",
      fullTimeStampDay: "",
      isEnterBtnEnable: "send",
      searchWords: "",
      birthdayStudentList: [],
      classList: [],
      showStudentChat: false,
      showAdminChat: false,
      studentList: [],
      studentDetails: {},
      studentMessage: "",
      adminMessage: "",
      adminMessageList: [],
      adminDetails: {},
      studentMessageList: [],
      adminList: [],
      showloader: false,
      studentsearchWords: "",
      sectionList: [],
      birthdayStudentAllList: [],
      holidayList: [],
      allHolidayList: [],
      leaveReasonList: ["Planned Leave", "Sick Leave", "Fever", "Others"],
      clockInBody: {
        userId: null,
        notes: "",
      },
      messageDetails: {
        class: null,
        classRoom: null,
      },
      messages: [
        {
          username: "User", // replace with user authentication
          message: "this.newMessage",
        },
      ],
      dayList: [],
      schoolDayObj: {},
      clockOutBody: {},
      isClockedIn: false,
      clockedInTime: null,
      isClockedOut: false,
      clockedOutTime: null,
      minDatesLeaveApply: null,
      maxDatesLeaveApply: null,
      leaveDetails: {
        from: null,
        to: null,
        reason: "Planned Leave",
        notes: "",
      },
    };
  },

  created() {
    setInterval(this.getNow, 1000);
    this.getBirthdayStudentList();
    this.getHolidayList();
    this.getClockInInfo();
    this.initCompent();

    socket.on("new_mesage_student", (message) => {
      if (message) {
        if (
          this.studentDetails &&
          this.studentDetails.parent &&
          this.studentDetails.parent._id == message.userId
        ) {
          this.studentMessageList.push(message);

          let obj = {
            details: {
              userId: message.userId,
              teacher: message.teacher,
            },
            data: {
              isTeacherView: true,
            },
          };

          socket.emit("student-message-read-teacher", obj);
          var checkContainerId = document.getElementById("chat-container");

          if (checkContainerId) {
            setTimeout(() => {
              var chatContainer = document.getElementById("chat-container");
              chatContainer.scrollTop = chatContainer.scrollHeight;
            }, 100);
          } else {
            for (let x of this.studentList) {
              if (message.userId == x.parent._id) {
                x.message.push(message);
              }
            }
          }
        } else {
          for (let x of this.studentList) {
            if (message.userId == x.parent._id) {
              x.message.push(message);
            }
          }
        }
      }
    });

    socket.on("new_mesage_admin", (message) => {
      if (message) {
        if (this.adminDetails && this.adminDetails._id == message.admin) {
          this.adminMessageList.push(message);

          let obj = {
            details: {
              admin: message.admin,
              teacher: message.teacher,
            },
            data: {
              isTeacherView: true,
            },
          };

          socket.emit("student-message-read-teacher", obj);

          var checkContainerId = document.getElementById("chat-container");

          if (checkContainerId) {
            setTimeout(() => {
              var chatContainer = document.getElementById("chat-container");
              chatContainer.scrollTop = chatContainer.scrollHeight;
            }, 100);
          } else {
            for (let x of this.adminList) {
              if (message.admin == x._id) {
                x.message.push(message);
              }
            }
          }
        } else {
          for (let x of this.adminList) {
            if (message.admin == x._id) {
              x.message.push(message);
            }
          }
        }
      }
    });
  },

  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 100 + "px",
      };
    },
    noOfDaysLeave() {
      if (this.leaveDetails.from && this.leaveDetails.to) {
        let dates = [];

        const currentDate = new Date(this.leaveDetails.to);
        const dd = new Date(this.leaveDetails.from);

        const firstDayOfMonth = new Date(
          dd.getFullYear(),
          dd.getMonth(),
          dd.getDate()
        ); // Get the first day of the month

        while (currentDate >= firstDayOfMonth) {
          const formattedDate = currentDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const dayValue = currentDate.toLocaleString("en-US", {
            weekday: "long",
          }); // Format the date as "Monday - May 29"

          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Months are zero-based, so we add 1
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          if (this.schoolDayObj[dayValue]) {
            dates.push({
              date: formattedDate,
              createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            }); // Add the formatted date and other data to the array
          }

          currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
        }
        return dates.length;
      } else {
        return 0;
      }
    },

    clockedInDiffTime() {
      if (this.clockOutBody.clockInTime && this.clockOutBody.clockOutTime) {
        var hour1 = this.clockOutBody.clockedInTimeValue.split(":")[0];
        var hour2 = this.clockOutBody.clockedOutimeValue.split(":")[0];
        var min1 = this.clockOutBody.clockedInTimeValue.split(":")[1];
        var min2 = this.clockOutBody.clockedOutimeValue.split(":")[1];

        var diff_hour = hour2 - hour1;
        var diff_min = min2 - min1;
        if (diff_hour < 0) {
          diff_hour += 24;
        }
        if (diff_min < 0) {
          diff_min += 60;
          diff_hour--;
        } else if (diff_min >= 60) {
          diff_min -= 60;
          diff_hour++;
        }
        // const timeDifferenceInMilliseconds = new Date(this.clockOutBody.clockOutTime) - new Date(this.clockOutBody.clockInTime);

        // const seconds = Math.floor(timeDifferenceInMilliseconds / 1000);
        // const minutes = Math.floor(seconds / 60);
        // const hours = Math.floor(minutes / 60);
        // const days = Math.floor(hours / 24);

        return `${diff_hour % 24}hrs : ${diff_min % 60}mins`;
      } else {
        return "";
      }
    },
    groupedStudentMessages() {
      // Group messages by day
      const grouped = {};
      this.studentMessageList.forEach((message) => {
        const day = this.formatDay(message.date);
        if (!grouped[day]) {
          grouped[day] = [];
        }
        grouped[day].push(message);
      });
      return grouped;
    },
    groupedAdminMessages() {
      // Group messages by day
      const grouped = {};
      this.adminMessageList.forEach((message) => {
        const day = this.formatDay(message.date);
        if (!grouped[day]) {
          grouped[day] = [];
        }
        grouped[day].push(message);
      });
      return grouped;
    },
  },
  watch: {
    searchWords: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.getInternalUserList();
      }
    },
    studentsearchWords: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.getStudentList();
      }
    },
  },

  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter" && this.isEnterBtnEnable == "enter") {
        this.sendToStudent();
      }
    },
    handleKeyPressAdmin(event) {
      if (event.key === "Enter" && this.isEnterBtnEnable == "enter") {
        this.sendToAdmin();
      }
    },
    showOptions() {
      this.updateUser();
      localStorage.setItem("messageAction", this.isEnterBtnEnable);
    },
    async updateUser() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let requestBody = {
          _id: userData.userDetails._id,
          isEnterBtnEnable: this.isEnterBtnEnable,
        };
        const response = await ViService.viXPost(
          "/user/updateMessageUser",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    formatDayHeader(day) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (day === this.formatDay(today)) {
        return "Today";
      } else if (day === this.formatDay(yesterday)) {
        return "Yesterday";
      } else {
        return day;
      }
    },
    formatDay(timestamp) {
      const date = new Date(timestamp);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    formatTime(sss) {
      let timestamp = new Date(sss);
      if (timestamp) {
        return `${timestamp.getHours()}:${
          timestamp.getMinutes() < 10 ? "0" : ""
        }${timestamp.getMinutes()}`;
      } else {
        return "";
      }
    },

    async initCompent() {
      await this.getClassWithSection();
      await this.getAccountDetails();
      this.getInternalUserList();

      let data = localStorage.getItem("messageAction");

      this.isEnterBtnEnable = data || "send";

      // await this.getVisitorList()
    },
    async getInternalUserList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/teacher/getInternalUserListForMessage?viewType=list&search=${this.searchWords}`,
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);

          if (data.length > 0) {
            let finalData = data.filter(
              (x) => x.profile.name == "School Admin"
            );

            //let data = finalData

            finalData.sort((a, b) => b.message.length - a.message.length);
            this.adminList = finalData;
          } else {
            this.adminList = [];
          }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getAccountSettingConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          if (details.workingDayList && details.workingDayList.length > 0) {
            this.dayList = [];

            const filteredObjects = details.workingDayList.filter(
              (obj) => obj.isselected
            );

            if (filteredObjects.length) {
              this.dayList = filteredObjects.map((obj) => obj.name);

              const groupByStudentId = this.dayList.reduce((r, a) => {
                r[a] = a; // set the id
                return r;
              }, {});

              this.schoolDayObj = groupByStudentId;
            }
          }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    formatTimestamp(timestamp) {
      const now = new Date();
      const messageDate = new Date(timestamp);

      // Check if the message was sent today
      if (
        now.getDate() === messageDate.getDate() &&
        now.getMonth() === messageDate.getMonth() &&
        now.getFullYear() === messageDate.getFullYear()
      ) {
        return this.formatTime(messageDate);
      }

      // Check if the message was sent yesterday
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      if (
        yesterday.getDate() === messageDate.getDate() &&
        yesterday.getMonth() === messageDate.getMonth() &&
        yesterday.getFullYear() === messageDate.getFullYear()
      ) {
        return "Yesterday";
      }

      // Display the full date for other days
      return `${messageDate.toLocaleDateString()} ${this.formatTime(
        messageDate
      )}`;
    },
    // formatTime(timestamp) {
    //   return `${timestamp.getHours()}:${(timestamp.getMinutes() < 10 ? '0' : '')}${timestamp.getMinutes()}`;
    // },
    formatTime12Hour(data) {
      let date = new Date(data);
      // Extract hours, minutes, and AM/PM indicator
      const hours = date.getHours() % 12 || 12; // Ensure 12-hour format
      const minutes = date.getMinutes();
      const period = date.getHours() < 12 ? "AM" : "PM";

      // Format as HH:MM AM/PM
      const formattedTime = `${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${period}`;

      return formattedTime;
    },
    async sendToAdmin() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          admin: this.adminDetails._id,
          teacher: userData.userDetails._id,
          message: this.adminMessage,
          fromUserType: "Teacher",
          isTeacherView: true,
          isAdminView: false,
          date: new Date(),
        };

        socket.emit("teacher-message-admin", obj);

        this.adminMessageList.push(obj);

        this.adminMessage = "";

        setTimeout(() => {
          var chatContainer = document.getElementById("chat-container");
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 100);

        //   const response = await ViService.viXPost("/home/saveMessageAdminToTeacher",obj,userData.token);

        //   if (response.isSuccess) {
        //     this.adminMessage = ''
        //     this.getTeacherAndAdminMessageList(this.adminDetails._id)
        //     setTimeout(() => {
        //     var chatContainer = document.getElementById('chat-container');
        //     chatContainer.scrollTop = chatContainer.scrollHeight
        //    }, 100);
        //   //this.$toasted.success(response.message);
        //   } else {
        //   this.$toasted.error(response.message);

        //   }
      }
    },
    async getAdminTeacherReadMessageList(adminId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        //  const response = await ViService.viXGet(`/home/readAdminTeacherMessage/${adminId}/${userData.userDetails._id}/Teacher`,userData.token);
        let obj = {
          details: {
            admin: adminId,
            teacher: userData.userDetails._id,
          },
          data: {
            isTeacherView: true,
          },
        };

        socket.emit("admin-message-read-teacher", obj);

        for (let x of this.adminList) {
          if (adminId == x._id) {
            x.message = [];
          }
        }
        // if (response.isSuccess) {
        //     this.getInternalUserList()
        // } else {
        //   this.$toasted.error(response.message);
        //    if(response.message == 'Your session has expired, please login'){
        //         localStorage.removeItem('user');
        //         this.$router.push('/login');
        //  }
        // }
      }
    },
    async getClassWithSection() {
      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        const response = await ViService.viXGet(
          "/teacher/getAssignedTeacherwithClassSectionList?viewType=list",
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          this.$store.dispatch("setClassAndSectionList", resultData.data);
          this.classList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }
      } else {
        let getClassCounts = this.$store.getters.getClassAndSectionList;

        if (getClassCounts.length == 0) {
          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
            const response = await ViService.viXGet(
              `/academic/class_with_sections`,
              userData.token
            );

            if (response.isSuccess) {
              const resultData = secureUI.secureGet(response.data);
              this.$store.dispatch("setClassAndSectionList", resultData.data);
              this.classList = resultData.data;
            } else {
              this.$toasted.error(response.message);
            }
          }
        } else {
          this.classList = this.$store.getters.getClassAndSectionList;
        }
      }

      if (this.classList.length > 0) {
        this.messageDetails.class = this.classList[0]._id;
        await this.getSection(this.messageDetails.class);
      }

      //selectedClass: null,
      //selectedSection: null,
    },
    getSection(classId) {
      let tempSections = [];
      //  const letters = new Set();
      let allSections = this.classList.find((x) => x._id == classId).sections;

      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        let sections = allSections.filter((x) => x.assignedTeachers.length > 0);

        if (sections.length > 0) {
          for (let x of sections) {
            for (let y of x.assignedTeachers) {
              if (y.teacherId && y.teacherId == userData.userDetails._id) {
                // this.assignedSubjects.push(y.subjectId)
                tempSections.push(x._id);
              }
            }
          }
          // Create a Set from the array to remove duplicates
          let uniqueSet = new Set(tempSections);

          // Convert the Set back to an array
          let uniqueArray = Array.from(uniqueSet);

          this.sectionList = sections.filter((s) =>
            uniqueArray.includes(s._id)
          );
        }
      } else {
        this.sectionList = allSections;
      }
      if (this.sectionList.length > 0) {
        this.messageDetails.classRoom = this.sectionList[0]._id;
        this.getStudentList();
      }
    },
    openChat(data) {
      (this.studentDetails = data),
        (this.studentMessageList = []),
        this.$bvModal.show("messageStudentPopUp");
      this.getStudentMessageList(this.studentDetails.parent._id);

      if (data.message.length > 0) {
        this.getReadMessageList(this.studentDetails.parent._id);
      }

      setTimeout(() => {
        var chatContainer = document.getElementById("chat-container");
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 100);
    },
    closeStudentChat() {
      this.$bvModal.hide("messageStudentPopUp");
      this.studentDetails = {};
    },
    closeAdminChat() {
      this.$bvModal.hide("messageAdminPopUp");
      this.adminDetails = {};
    },
    openAdminChat(data) {
      (this.adminDetails = data),
        (this.adminMessageList = []),
        this.$bvModal.show("messageAdminPopUp");
      this.getTeacherAndAdminMessageList(this.adminDetails._id);

      if (data.message.length > 0) {
        this.getAdminTeacherReadMessageList(this.adminDetails._id);
      }

      //this.getStudentMessageList(this.studentDetails.parent._id)
      //this.getReadMessageList(this.studentDetails.parent._id)

      setTimeout(() => {
        var chatContainer = document.getElementById("chat-container");
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 400);
    },
    async getTeacherAndAdminMessageList(adminId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/home/getTeacherAndAdminMessage/${adminId}/${userData.userDetails._id}`,
          userData.token
        );
        if (response.isSuccess) {
          this.adminMessageList = secureUI.secureGet(response.data);

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async getReadMessageList(studentId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          details: {
            userId: studentId,
            teacher: userData.userDetails._id,
          },
          data: {
            isTeacherView: true,
          },
        };

        socket.emit("student-message-read-teacher", obj);

        for (let x of this.studentList) {
          if (studentId == x.parent._id) {
            x.message = [];
          }
        }
      }
    },
    // openAdminChat(data) {
    //   this.showAdminChat = true;
    // },

    openLeavePopUp() {
      // var newDate = new Date().add(30).days();

      var currentDate1 = new Date();
      var currentDate2 = new Date();
      var numberOfDaysToAdd = 30;

      let minDatesLeave = new Date(
        currentDate1.setDate(currentDate1.getDate() - numberOfDaysToAdd)
      );

      this.minDatesLeaveApply = this.dateFormat(minDatesLeave);

      let maxDatesLeave = new Date(
        currentDate2.setDate(currentDate2.getDate() + numberOfDaysToAdd)
      );

      this.maxDatesLeaveApply = this.dateFormat(maxDatesLeave);

      this.$bvModal.show("leaveApplyPopUp");
    },
    async sendToStudent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          student: this.studentDetails._id,
          userId: this.studentDetails.parent._id,
          teacher: userData.userDetails._id,
          message: this.studentMessage,
          fromUserType: "Teacher",
          isTeacherView: true,
          isStudentView: false,
          date: new Date(),
        };

        socket.emit("teacher-message-student", obj);

        this.studentMessageList.push(obj);

        this.studentMessage = "";

        setTimeout(() => {
          var chatContainer = document.getElementById("chat-container");
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 100);
      }
    },

    async getStudentMessageList(studentId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/home/getStudentTeacherMessage/${userData.userDetails._id}/${studentId}`,
          userData.token
        );
        if (response.isSuccess) {
          this.studentMessageList = secureUI.secureGet(response.data);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (this.messageDetails.class && this.messageDetails.classRoom) {
          const response = await ViService.viXGet(
            `/contact/view/getStudentListForMessage?classId=${this.messageDetails.class}&sectionId=${this.messageDetails.classRoom}&search=${this.studentsearchWords}`,
            userData.token
          );
          if (response.isSuccess) {
            //  this.studentList = secureUI.secureGet(response.data);
            let data = secureUI.secureGet(response.data);

            data.sort((a, b) => b.message.length - a.message.length);
            this.studentList = data;

            //  console.log("")
            //  this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    openBirthdaypopup() {
      this.$bvModal.show("birthDayPopUp");
    },
    closeHolidayPopUp() {
      this.allHolidayList = [];

      this.$bvModal.hide("holidayPopUp");
    },
    openHolidaypopup() {
      this.allHolidayList = [];
      this.getAllHolidayList();
      this.$bvModal.show("holidayPopUp");
    },
    closeBirthDayPopUp() {
      this.$bvModal.hide("birthDayPopUp");
    },
    checkToDate() {
      this.leaveDetails.to = null;
    },
    dateFormat(value) {
      if (value) {
        let date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
      }
    },
    closeLeavePopUp() {
      this.$bvModal.hide("leaveApplyPopUp");
      this.leaveDetails.from = null;
      this.leaveDetails.to = null;
      this.leaveDetails.notes = "";
      this.leaveDetails.reason = "Planned Leave";
    },
    async updateLeaveApply() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let dates = [];

        const currentDate = new Date(this.leaveDetails.to);
        const dd = new Date(this.leaveDetails.from);

        const firstDayOfMonth = new Date(
          dd.getFullYear(),
          dd.getMonth(),
          dd.getDate()
        ); // Get the first day of the month

        // this.currentMonth = currentDate.getMonth() + 1;

        while (currentDate >= firstDayOfMonth) {
          const formattedDate = currentDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const dayValue = currentDate.toLocaleString("en-US", {
            weekday: "long",
          }); // Format the date as "Monday - May 29"

          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Months are zero-based, so we add 1
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          if (this.schoolDayObj[dayValue]) {
            dates.push({
              date: formattedDate,
              createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            }); // Add the formatted date and other data to the array
          }

          currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
        }

        // console.log
        let obj = {
          from: this.leaveDetails.from,
          to: this.leaveDetails.to,
          notes: this.leaveDetails.notes,
          reason: this.leaveDetails.reason,
          noOfDates: dates,
          type: "Staff Leave",
          status: "Pending Approval",
        };

        const response = await ViService.viXPost(
          "/home/leave/apply/staff",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.closeLeavePopUp();
          this.$toasted.success("Leave Applied Successfully");
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    moveRightSide() {
      document.getElementById("HolidayPic").scrollLeft += 222;
    },
    moveLeftSide() {
      document.getElementById("HolidayPic").scrollLeft -= 222;
    },
    getNow() {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = time;
      // this.fullTimeStamp =
      //   weekday[today.getDay()] +
      //   " - " +
      //   today.getDate() +
      //   " , " +
      //   months[today.getMonth()] +
      //   " - " +
      //   today.getFullYear();
      this.fullTimeStamp =
        today.getDate() +
        "  " +
        months[today.getMonth()] +
        "  " +
        today.getFullYear();
      this.fullTimeStampDay = weekday[today.getDay()];
    },
    navToAttendance() {
      console.log("in nav to attendance ==> ");
      this.$router.push("/teacher/attendance/management");
    },
    navToLeave() {
      console.log("in nav to attendance ==> ");
      this.$router.push("/teacher/leave/management/view");
    },

    async getBirthdayStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/birthday/student",
          userData.token
        );
        if (response.isSuccess) {
          const today = new Date();
          var todayDate = today.getDate();
          var result = secureUI.secureGet(response.data);

          (this.birthdayStudentList = result.todayBirthDays || []),
            (this.birthdayStudentAllList = result.allBirthDays || []);

          // this.birthdayStudentList = result.filter((x) => x.dobDay >= 15);
          // this.birthdayStudentList = result
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getHolidayList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/holiday",
          userData.token
        );
        if (response.isSuccess) {
          this.holidayList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getAllHolidayList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/all/holiday",
          userData.token
        );
        if (response.isSuccess) {
          this.allHolidayList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    // async clockIn() {
    //   let userData = secureUI.sessionGet("user");

    //   if (!userData) {
    //     this.$toasted.error("Please login and do the action");
    //     this.$router.push("/login");
    //   } else {
    //     var newDate = new Date();
    //     var hour =
    //       newDate.getHours() < 10
    //         ? "0" + newDate.getHours()
    //         : newDate.getHours();
    //     var minute =
    //       newDate.getMinutes() < 10
    //         ? "0" + newDate.getMinutes()
    //         : newDate.getMinutes();
    //     var second =
    //       newDate.getSeconds() < 10
    //         ? "0" + newDate.getSeconds()
    //         : newDate.getSeconds();
    //     var meridian = newDate.getHours() < 12 ? "AM" : "PM";

    //     this.viLoader = true;

    //     this.clockInBody["clockedInTimeValue"] = hour + ":" + minute;
    //     this.clockInBody["clockedInTimeLocalValue"] =
    //       hour + ":" + minute + " " + meridian;

    //     this.clockInBody.userId = null;
    //     this.clockInBody.notes = "";

    //     const response = await ViService.viXPost(
    //       "/home/clockin/save",
    //       this.clockInBody,
    //       userData.token
    //     );

    //     this.viLoader = null;

    //     if (response.isSuccess) {
    //       this.isClockedIn = true;
    //       this.clockOutBody = response.data;

    //       this.clockedInTime = hour + ":" + minute + " " + meridian;
    //       this.$toasted.success("Clocked in successfully");
    //     } else {
    //       this.$toasted.error(response.message);
    //     }
    //   }
    // },
    // async clockOut() {
    //   let userData = secureUI.sessionGet("user");

    //   if (!userData) {
    //     this.$toasted.error("Please login and do the action");
    //     this.$router.push("/login");
    //   } else {
    //     var newDate = new Date();
    //     var hour =
    //       newDate.getHours() < 10
    //         ? "0" + newDate.getHours()
    //         : newDate.getHours();
    //     var minute =
    //       newDate.getMinutes() < 10
    //         ? "0" + newDate.getMinutes()
    //         : newDate.getMinutes();
    //     var second =
    //       newDate.getSeconds() < 10
    //         ? "0" + newDate.getSeconds()
    //         : newDate.getSeconds();
    //     var meridian = newDate.getHours() < 12 ? "AM" : "PM";

    //     // this.viLoader = true;

    //     this.clockOutBody["clockedOutimeValue"] = hour + ":" + minute;
    //     this.clockOutBody["clockedOutTimeLocalValue"] =
    //       hour + ":" + minute + " " + meridian;

    //     // this.viLoader = true;
    //     //this.clockOutBody.userId = null;
    //     //this.clockInBody.notes = "";

    //     const response = await ViService.viXPost(
    //       "/home/clockout/save",
    //       this.clockOutBody,
    //       userData.token
    //     );

    //     this.viLoader = null;

    //     if (response.isSuccess) {
    //       this.getClockInInfo();
    //       this.$toasted.success("Clocked Out successfully");
    //     } else {
    //       this.$toasted.error(response.message);
    //     }
    //   }
    // },

    async clockIn() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let clockinLocationObj = {};
        this.showloader = true;

        await navigator.geolocation.getCurrentPosition((position) => {
          (clockinLocationObj["latitude"] = position.coords.latitude),
            (clockinLocationObj["longitude"] = position.coords.longitude);

          this.clockInBody["clockinLocation"] = clockinLocationObj;

          this.callclockIn();
        });
      }
    },
    async callclockIn() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (
          this.clockInBody["clockinLocation"] &&
          this.clockInBody["clockinLocation"].longitude
        ) {
          var newDate = new Date();
          var hour =
            newDate.getHours() < 10
              ? "0" + newDate.getHours()
              : newDate.getHours();
          var minute =
            newDate.getMinutes() < 10
              ? "0" + newDate.getMinutes()
              : newDate.getMinutes();
          var second =
            newDate.getSeconds() < 10
              ? "0" + newDate.getSeconds()
              : newDate.getSeconds();
          var meridian = newDate.getHours() < 12 ? "AM" : "PM";

          this.viLoader = true;

          this.clockInBody["clockedInTimeValue"] = hour + ":" + minute;
          this.clockInBody["clockedInTimeLocalValue"] =
            hour + ":" + minute + " " + meridian;

          this.clockInBody.userId = null;
          this.clockInBody.notes = "";

          const response = await ViService.viXPost(
            "/home/clockin/save",
            this.clockInBody,
            userData.token
          );

          this.viLoader = null;

          if (response.isSuccess) {
            this.isClockedIn = true;
            this.clockOutBody = response.data;

            this.clockedInTime =
              hour + ":" + minute + ":" + second + "" + meridian;
            this.$toasted.success("Clocked in successfully");
          } else {
            this.$toasted.error(response.message);
          }
          this.showloader = false;
        }
      }
    },
    async clockOut() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let clockinLocationObj = {};
        this.showloader = true;

        await navigator.geolocation.getCurrentPosition((position) => {
          (clockinLocationObj["latitude"] = position.coords.latitude),
            (clockinLocationObj["longitude"] = position.coords.longitude);

          this.clockOutBody["clockoutLocation"] = clockinLocationObj;

          this.callclockOut();
        });
      }
    },
    async callclockOut() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        var newDate = new Date();
        var hour =
          newDate.getHours() < 10
            ? "0" + newDate.getHours()
            : newDate.getHours();
        var minute =
          newDate.getMinutes() < 10
            ? "0" + newDate.getMinutes()
            : newDate.getMinutes();
        var second =
          newDate.getSeconds() < 10
            ? "0" + newDate.getSeconds()
            : newDate.getSeconds();
        var meridian = newDate.getHours() < 12 ? "AM" : "PM";

        // this.viLoader = true;

        this.clockOutBody["clockedOutimeValue"] = hour + ":" + minute;
        this.clockOutBody["clockedOutTimeLocalValue"] =
          hour + ":" + minute + " " + meridian;

        const response = await ViService.viXPost(
          "/home/clockout/save",
          this.clockOutBody,
          userData.token
        );

        this.viLoader = null;

        if (response.isSuccess) {
          this.getClockInInfo();
          this.$toasted.success("Clocked Out successfully");
        } else {
          this.$toasted.error(response.message);
        }
        this.showloader = false;
      }
    },
    async getClockInInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/clockin/info",
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
          if (Object.keys(result).length === 0) {
            //this.isClockedIn = true;
            //this.clockedInTime = result.clockInTime;
          } else {
            this.clockOutBody = result;

            if (
              result.clockInTime &&
              result.status != "Leave" &&
              result.status != "Pending Approval"
            ) {
              this.isClockedIn = true;

              this.clockedInTime = result.clockedInTimeLocalValue;
            }

            if (result.clockOutTime) {
              this.clockedOutTime = result.clockedOutTimeLocalValue;
              this.isClockedIn = true;
            }
          }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
  },
};
</script>
<style>
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.chat-container {
  margin-top: -9px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  height: 300px;
  /* Set a fixed height for the chat container */
  overflow-y: scroll;
  /* Enable vertical scrolling */
}

.message {
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  clear: both;
  overflow: hidden;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

.user-message {
  background-color: #e0f7fa;
}

.other-message {
  background-color: #fff;
}

.sender-message {
  background-color: #e0f7fa;
  float: right;
}

.receiver-message {
  background-color: #e9d1d1;
  float: left;
}

.message-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  resize: none;
}

.send-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.timestamp {
  font-size: 12px;
  float: right;
  color: #888;
  text-align: right;
}

.day-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

/* Hide the radio button */
input[type="radio"] {
  display: none;
}

/* Style the label to make it look clickable */
.custLabel {
  cursor: pointer;
  padding: 5px;
  background: beige;
  /* // border: 1px solid #ccc; */
  display: inline-block;
  margin: 5px;
  font-size: 0.89rem;
  border-radius: 10rem;
  /* color: #fff; */
  padding: 0.39rem 1.3rem 0.3rem 1.3rem;
  /* width: 140px; */
}



.fa-stack[data-count]:after {
  position: absolute;
  right: 0%;
  top: 1%;
  content: attr(data-count);
  font-size: 30%;
  padding: 0.6em;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  background: rgba(255, 0, 0, 0.85);
  text-align: center;
  min-width: 2em;
  font-weight: bold;
}
#messageStudentPopUp .modal-content {
  width: 50% !important;
  /* Could be more or less, depending on screen size */
}
#messageAdminPopUp .modal-content {
  width: 50% !important;
  /* Could be more or less, depending on screen size */
}

</style>
